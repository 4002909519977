import { AnyAction, Reducer } from 'redux';
import { createReducer } from 'reduxsauce';

import { RegistrationTypes } from './registration.actions';
import { initState } from './registration.state';
import { State } from './registration.types';

const setFormFields: Reducer = (state: State, { sections, isValid }: AnyAction): State => ({
  ...state,
  sections,
  isValid,
});

const setCompanies: Reducer = (state: State, { companies }: AnyAction): State => ({
  ...state,
  companies,
});

const setDuties: Reducer = (state: State, { duties }: AnyAction): State => ({
  ...state,
  duties,
});

const setAccountType: Reducer = (state: State, { accountType }: AnyAction): State => ({
  ...state,
  accountType,
});

const setPositions: Reducer = (state: State, { positions }: AnyAction): State => ({
  ...state,
  positions,
});

const clearForm: Reducer = (): State => ({
  ...initState,
});

export const reducer: Reducer = createReducer(initState, {
  [RegistrationTypes.SET_FORM_FIELDS]: setFormFields,
  [RegistrationTypes.SET_COMPANIES]: setCompanies,
  [RegistrationTypes.SET_DUTIES]: setDuties,
  [RegistrationTypes.SET_ACCOUNT_TYPE]: setAccountType,
  [RegistrationTypes.SET_POSITIONS]: setPositions,
  [RegistrationTypes.CLEAR_FORM]: clearForm,
});
