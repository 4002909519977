import { State } from './registration.types';

export const initState: State = {
  sections: null,
  accountType: null,
  isValid: false,
  companies: null,
  duties: null,
  positions: null,
};
