import { createActions, CreatedActions } from 'reduxsauce';

const { Types, Creators }: CreatedActions = createActions({
  fetchForm: null,
  setFormFields: ['sections', 'isValid'],
  fetchCompanies: ['division'],
  setCompanies: ['companies'],
  fetchDuties: ['division'],
  setDuties: ['duties'],
  setAccountType: ['accountType'],
  sendForm: ['data', 'onFinish'],
  fetchPositions: ['division'],
  setPositions: ['positions'],
  clearForm: null,
});

export { Types as RegistrationTypes, Creators as RegistrationActions };
