import {
  Breakpoint,
  Button,
  ButtonSize,
  ButtonTheme,
  ButtonType,
  Color,
  IconButton,
  IconButtonSize,
  IconButtonVariant,
  IconName,
  InfoChipTheme,
  Input,
  InputVariant,
  ShapeVariant,
  TextButton,
  TextButtonTheme,
  TypographyVariant,
  UseState,
} from '@elearning-platform/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { TransProps, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { AppConfig } from '../../../../appConfig';
import { Path } from '../../../../enums';
import LogoLight from '../../../../images/logoFullLight.svg';
import { UserContextActions } from '../../../../userContext/redux/userContext.reducer';
import { selectUserData } from '../../../../userContext/redux/userContext.selectors';

import { schema } from './loginView.schema';
import {
  BottomBar,
  Container,
  Content,
  Form,
  FormButtons,
  Header,
  HeaderButtons,
  Inputs,
  Logo,
  PlatformName,
  SignInButton,
  StyledInfoChip,
  StyledShape,
  Title,
  TitleWrapper,
} from './loginView.styled';
import { Answers, ControllerRender, LoginViewProps, UserData } from './loginView.types';

export const LoginView: React.FC<LoginViewProps> = ({ onSignUpClick }: LoginViewProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const dispatch: Dispatch<AnyAction> = useDispatch();
  const userData: UserData | null | undefined = useSelector(selectUserData) as UserData | null | undefined;
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const isDesktop: boolean = useMediaQuery({ query: Breakpoint.Desktop });
  const [validationEnabled, setValidationEnabled]: UseState<boolean> = useState<boolean>(false);

  const { control, handleSubmit, formState: { errors } }: UseFormReturn<Answers> = useForm({
    defaultValues: {
      login: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const insertLinkInError: (error: string) => JSX.Element | string | null = (error: string): JSX.Element | string | null => {
    if (!error) return null;

    const errorContent: string[] = error.split('{link}');

    return error.indexOf('{link}') === -1
      ? error
      : (
          <>
            {errorContent[0]}
            <Link to="#przypomnijhaslo">{t('userContext.login.remindPassword')}</Link>
            {errorContent[1]}
          </>
        );
  };

  const onSubmit: (data: Answers) => void = (data: Answers): void => {
    dispatch(UserContextActions.tryLogin(data.login, data.password));
  };

  const renderInfo: () => JSX.Element | null = (): JSX.Element | null => {
    if (!!validationEnabled && !userData?.success && !!userData?.message) {
      return (
        <StyledInfoChip
          content={insertLinkInError(userData?.message)}
          icon={IconName.Warning}
          variant={InfoChipTheme.Yellow}
        />
      );
    }

    if (!!validationEnabled && !userData?.message && (!!errors.login || !!errors.password)) {
      return (
        <StyledInfoChip
          content={t('loginView.fillTheFields')}
          icon={IconName.Warning}
          variant={InfoChipTheme.Yellow}
        />
      );
    }

    return null;
  };

  return (
    <Container>
      <Header>
        <Logo src={LogoLight} />
        {!!isTablet && (
          <HeaderButtons>
            <IconButton
              externalUrl={`mailto:${AppConfig.platformContactEmail}`}
              icon={IconName.EmailOutlined}
              size={IconButtonSize.Large}
              variant={isDesktop ? IconButtonVariant.SecondaryGrey : IconButtonVariant.PrimaryWhite}
            />
            <Button
              icon={IconName.Add}
              label={t('loginView.signUp')}
              onClick={onSignUpClick}
              reversed
              size={ButtonSize.Large}
              variant={isDesktop ? ButtonTheme.SecondaryGray : ButtonTheme.PrimaryWhite}
            />
          </HeaderButtons>
        )}
      </Header>
      <Content>
        <TitleWrapper>
          <Title variant={isTablet ? TypographyVariant.DisplayMD : TypographyVariant.DisplaySM}>
            {t('loginView.welcome')}
          </Title>
          <PlatformName variant={isTablet ? TypographyVariant.DisplayMD : TypographyVariant.DisplaySM}>
            {AppConfig.platformName}
          </PlatformName>
        </TitleWrapper>
        <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
          <Inputs>
            {!isTablet && renderInfo()}
            <Controller
              control={control}
              name='login'
              render={({ field }: ControllerRender<'login'>): JSX.Element => (
                <Input
                  {...field}
                  autoComplete='new-login'
                  id='login'
                  label={t('loginView.login')}
                  variant={InputVariant.Transparent}
                  withError={!!errors.login}
                />
              )}
            />
            <Controller
              control={control}
              name='password'
              render={({ field }: ControllerRender<'password'>): JSX.Element => (
                <Input
                  {...field}
                  autoComplete='new-password'
                  id='password'
                  label={t('loginView.password')}
                  type='password'
                  variant={InputVariant.Transparent}
                  withError={!!errors.password}
                />
              )}
            />
            {!!isTablet && renderInfo()}
          </Inputs>
          <FormButtons>
            <SignInButton
              icon={IconName.ChevronRight}
              label={t('loginView.signIn')}
              onClick={(): void => setValidationEnabled(true)}
              reversed
              size={ButtonSize.Large}
              type={ButtonType.Submit}
              variant={ButtonTheme.PrimaryBrand}
            />
            {isTablet ? (
              <TextButton
                internalPath={Path.RemindPassword}
                label={t('loginView.remindPassword')}
                variant={TextButtonTheme.TertiaryWhite}
              />
            ) : (
              <Button
                icon={IconName.Add}
                label={t('loginView.signUp')}
                onClick={onSignUpClick}
                reversed
                size={ButtonSize.Large}
                variant={ButtonTheme.SecondaryWhite}
              />
            )}
          </FormButtons>
        </Form>
      </Content>
      {!isTablet && (
        <BottomBar>
          <TextButton
            internalPath={Path.RemindPassword}
            label={t('loginView.remindPassword')}
            variant={TextButtonTheme.TertiaryWhite}
          />
          <TextButton
            externalUrl={`mailto:${AppConfig.platformContactEmail}`}
            label={t('loginView.help')}
            variant={TextButtonTheme.TertiaryWhite}
          />
        </BottomBar>
      )}
      <StyledShape color={Color.Brand300} variant={ShapeVariant.Circle1} />
    </Container>
  );
};
