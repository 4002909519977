import { t } from 'i18next';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/typedef
export const schema = yup.object({
  account_type: yup.string().required(),
  user_type: yup.string().required(),
  user_name: yup
    .string()
    .required(t('registration.step2.fieldRequired'))
    .min(2, t('registration.step2.invalidName'))
    .max(64, t('registration.step2.invalidName')),
  user_surname: yup
    .string()
    .required(t('registration.step2.fieldRequired'))
    .min(2, t('registration.step2.invalidSurname'))
    .max(128, t('registration.step2.invalidSurname')),
  user_email: yup
    .string()
    .required(t('registration.step2.fieldRequired'))
    .matches(
      // eslint-disable-next-line @stylistic/max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      t('registration.step2.invalidEmail')
    )
    .min(6, t('registration.step2.invalidEmail'))
    .max(128, t('registration.step2.invalidEmail')),
  user_company_id: yup.number(),
  user_company_name: yup
    .string()
    .min(2, t('registration.step2.invalidCompanyName'))
    .max(64, t('registration.step2.invalidCompanyName')),
  user_position_id: yup.number(),
  user_position: yup
    .string()
    .required(t('registration.step2.fieldRequired'))
    .min(2, t('registration.step2.invalidPosition'))
    .max(64, t('registration.step2.invalidPosition')),
  user_date: yup.string().required(),
  user_pesel: yup
    .string()
    .matches(/^[0-9]{11}$/, t('registration.step2.invalidPesel')),
  user_purpose: yup
    .string()
    .min(2, t('registration.step2.invalidPurpose'))
    .max(500, t('registration.step2.invalidPurpose')),
}).required();
