import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { BackButton } from "../../../../components/backButton/backButton.component";
import { selectUserData } from "../../../../userContext/redux/userContext.selectors";
import { Expandable } from "../../../../UIElements/expandableHOC";
import {
  ContentContainer,
  MainTitle,
  BadgeText,
  Icon,
  Box,
  HeadCertificate,
  BoxTitle,
  InlineBoxInside,
  Badge,
  Rounded,
  Body,
  BoxInner,
} from "../../../../UIElements";
import { AppConfig } from "../../../../appConfig";
import { Color } from "../../../../theme";
import { selectCertificateData } from "../../redux/certificates.selectors";
import { CertificatesActions } from "../../redux/certificates.reducer";
import WomanImgMobile from "../../images/womanWithCarMobile.jpg";
import { PathGeneralItem } from "../pathGeneralItem/pathGeneralItem.component";
import { PathLearningItem } from "../pathLearningItem/pathLearningItem.component";

import {
  HeaderBadge,
  IconWrapper,
  ListHeader,
  MinutesBox,
  MinutesImage,
  MinutesContent,
  MinutesTitle,
  MinutesTextBox,
  MinutesIcon,
  MinutesText,
  MinutesValue,
  MinutesValueLarge,
  MinutesLabel,
} from "./certificatePath.styled";
import { IddCertificateName } from "./certificatePath.constants";

export const CertificatePath = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const certificate = useSelector(selectCertificateData);
  const user = useSelector(selectUserData);
  const [stages, setStages] = useState();

  useEffect(() => {
    dispatch(CertificatesActions.fetchCertificate(params.id));

    return () => {
      dispatch(CertificatesActions.setCertificate(null));
    };
  }, [params.id]);

  useEffect(() => {
    if (certificate) {
      setStages(certificate.stages.map((stage) => ({
        ...stage,
        passedNumber: Object.keys(stage.itemGroup)
          .map((group) => stage.itemGroup[group].items.filter((item) => item.passed).length)
          .reduce((result, item) => result + item, 0),
        all: Object.keys(stage.itemGroup)
          .map((group) => stage.itemGroup[group].items.length)
          .reduce((result, item) => result + item, 0),
      })));
    }
  }, [certificate]);

  return (
    <ContentContainer background="almostWhite">
      <BackButton defaultPath="/certyfikaty" />
      {certificate && (
        <>
          <HeaderBadge
            padding="50px 0"
            marginBottom={49}
            justifyContent="space-between"
          >
            <MainTitle>{certificate.name}</MainTitle>
            {certificate.downloadId &&
              <IconWrapper>
                <BadgeText
                  $background={Color.white}
                  cursor="pointer"
                  onClick={() => window.open(`${AppConfig.api}/download/certificate/${certificate.downloadId}?X-AUTH-TOKEN=${user.token}`)}
                >
                  <Icon icon="pdf_icon" size="22" color={Color.gray2} />
                </BadgeText>
                <BadgeText $background={Color.veryLightGreen}>
                  <Icon icon="status_checkbox" size="18" color={Color.green} />
                </BadgeText>
              </IconWrapper>
            }
          </HeaderBadge>
          {stages && certificate.stages.map((item, index) => (
            item.name === IddCertificateName ? (
              <MinutesBox key={`${item.name} ${item.sortIndex}`}>
                <MinutesImage src={WomanImgMobile} />
                <MinutesContent>
                  <MinutesTitle>{item.name}</MinutesTitle>
                  <MinutesTextBox>
                    <MinutesIcon />
                    <MinutesText>
                      <MinutesValue>
                        <MinutesValueLarge>
                          {Object.values(item.itemGroup).find((item) => item.name === IddCertificateName).items[0].completedMinutes}
                        </MinutesValueLarge>
                        {" / "}
                        {Object.values(item.itemGroup).find((item) => item.name === IddCertificateName).items[0].totalMinutes}
                      </MinutesValue>
                      <MinutesLabel>{t("certificates.certificate.certificate.minutes")}</MinutesLabel>
                    </MinutesText>
                  </MinutesTextBox>
                </MinutesContent>
              </MinutesBox>
            ) : (
              <Box
                border="dirtyWhite"
                $background={item.isEnabled ? "white" : "inherit"}
                key={`${item.name} ${item.sortIndex}`}
              >
                <Expandable>
                  <HeadCertificate width={100}>
                    <BoxTitle
                      fontWeight={700}
                      paddingBottom={8}
                      fontSize={1.8}
                      marginRight={20}
                      uppercase={true}
                    >
                      {item.name}
                    </BoxTitle>
                    <InlineBoxInside>
                      {!item.isEnabled ? (
                        <>
                          <BoxTitle
                            fontWeight={700}
                            fontSize={1.2}
                            color="grey4"
                            marginRight={10}
                          >
                            {t("certificates.certificate.certificate.inaccessible")}
                          </BoxTitle>
                          <Badge name="lock" />
                        </>
                      ) : item.requiredItemCount ? (
                        <BoxTitle
                          fontWeight={700}
                          fontSize={1.2}
                          color="grey4"
                          marginRight={10}
                          wordSpacing="5px"
                        >
                          {t("certificates.certificate.certificate.required")}: {item.requiredItemCount}
                        </BoxTitle>
                      ) : null}
                      {item.passed ? (
                        <Badge name="completed" />
                      ) : (
                        <Rounded
                          color="grey4"
                          $background={item.isEnabled ? "white" : "inherit"}
                          fontSize={12}
                          border="dirtyWhite"
                          marginLeft="10px"
                          marginTop="inherit"
                        >
                          {stages[index].passedNumber}  / {stages[index].all}
                        </Rounded>
                      )}
                    </InlineBoxInside>
                  </HeadCertificate>
                  <Body padding="50px 0 0">
                    {Object.keys(item.itemGroup).map((group) => (
                      <BoxInner
                        key={group}
                        width="100%"
                        marginTop="35px">
                        <ListHeader>{item.itemGroup[group].name}</ListHeader>
                        {item.itemGroup[group].items.map((props) => (
                          group === "1" ? (
                            <PathLearningItem props={props} key={props.id} />
                          ) : (
                            <PathGeneralItem
                              props={props}
                              type={group}
                              key={props.id}
                              downloadId={certificate.downloadId}
                            />
                          )
                        ))}
                      </BoxInner>
                    ))}
                  </Body>
                </Expandable>
              </Box>
            )
          ))}
        </>
      )}
    </ContentContainer>
  );
};
