/* eslint-disable @typescript-eslint/no-explicit-any */
import { put } from 'redux-saga/effects';

import * as registrationApi from '../services/api';

import { RegistrationActions } from './registration.actions';
import { FetchCompaniesAction, FetchPositionsAction } from './registration.types';

export function* fetchForm(): Generator {
  // TODO: remove this any
  const { data }: any = yield registrationApi.fetchForm();

  if (data.sections) {
    yield put(RegistrationActions.setFormFields(data.sections, data.is_valid));
  }
}

export function* fetchCompanies({ division }: FetchCompaniesAction): Generator {
  // TODO: remove this any
  const { data }: any = yield registrationApi.fetchCompanies(division);
  yield put(RegistrationActions.setCompanies(data));
}

export function* fetchDuties({ division }: FetchCompaniesAction): Generator {
  // TODO: remove this any
  const { data }: any = yield registrationApi.fetchDuties(division);
  yield put(RegistrationActions.setDuties(data));
}

export function* fetchPositions({ division }: FetchPositionsAction): Generator {
  // TODO: remove this any
  const { data }: any = yield registrationApi.fetchPositions(division);
  yield put(RegistrationActions.setPositions(data));
}

export function* sendForm({ data, onFinish }: any): Generator {
  const response: any = yield registrationApi.sendForm(data);
  if (response.status === 200) {
    onFinish();
  }
}
