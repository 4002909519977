import { colors } from "../UIGlobals";

import styled from "styled-components/";

const HeaderButton = styled.div`
  border-bottom: ${props => (props.$hasBorder ? `1px solid ${colors().dirtyWhite}` : "none")};
  padding: ${props => (props.hasHorizontalPadding ? "50px 0" : "40px 0 0")};
  margin-bottom: 49px;
  display: flex;
  align-items: center;
  gap: 10px;
`;
const HeaderTitle = styled.h1`
  font-size: 2.6rem;
  color: ${colors().black};
  font-weight: 700;
`;

export {
  HeaderButton,
  HeaderTitle,
};
