import { all, takeLatest } from 'redux-saga/effects';

import { RegistrationTypes } from './registration.actions';
import { fetchCompanies, fetchDuties, fetchForm, fetchPositions, sendForm } from './registration.sagas';

export function* watchRegistration() {
  yield all([
    // TODO: Change Reduxsauce for sth else, it cannot work with Typescript
    yield takeLatest(RegistrationTypes.FETCH_FORM, fetchForm),
    yield takeLatest(RegistrationTypes.FETCH_COMPANIES, fetchCompanies),
    yield takeLatest(RegistrationTypes.FETCH_DUTIES, fetchDuties),
    yield takeLatest(RegistrationTypes.FETCH_POSITIONS, fetchPositions),
    yield takeLatest(RegistrationTypes.SEND_FORM, sendForm),
  ]);
}
